import LoginPanel from "../components/LoginPanel";

const LoginPage = () => {
    return (
        <section className=''>
            <h4>Login</h4>
            <LoginPanel/>
        </section>
    );
}

export default LoginPage;
