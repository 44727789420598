import './VocaCard.css'

const VocaCard = ({ data }) => {
    
    return (
        <div className='voca-card'>
            <div className='voca-card-name'>
                {data.name}
            </div>
            <div className='vertical-rule'></div>
            <div className='voca-card-content'>
                <div className='voca-card-class'>{data.class}</div>
                {data.meaning}
                <div>ex. asd</div>
            </div>
        </div>
    );
}

export default VocaCard;