import { useState } from 'react';
import { Link } from 'react-router-dom';

import './AppHeader.css'

const AppHeader = () => {
    const [isMenuToggled, setMenuToggled] = useState(false);

    const menuHandler = () => {
        const target = document.querySelector('.header-wrapper');

        if (isMenuToggled) {
            target.style = `height : 50px;`
        }
        else {
            target.style = `height : 100vh;`
        }

        setMenuToggled(!isMenuToggled)
    }

    return (
        <div className='header-wrapper'>
            <header className='header-container'>
                <div className='header-bar'>
                    <div className='Logo'>🥭MangoVoca</div>

                    <span className="material-symbols-outlined" onClick={menuHandler}>menu</span>

                </div>

                {isMenuToggled ?
                    <nav className='header-nav'>
                        <Link to='/' onClick={menuHandler}>홈</Link>
                        <Link to='/quiz' onClick={menuHandler}>퀴즈</Link>
                        <Link to='/login' onClick={menuHandler}>로그인</Link>

                    </nav> : <></>}


            </header>
        </div>
    );
}

export default AppHeader;