import { Link } from "react-router-dom";

import "./LoginPanel.css"

const LoginPanel = () => {
    return (
        <div className='login-container-wrapper'>
            <div className='login-container'>

                <form id="login-form" action="/" method="POST">

                    <label for="id">아이디</label>
                    <input type="id" id="id" name="id" size={30} maxLength={100} />


                    <label for="password">비밀번호</label>
                    <input type="password" id="password" name="password" />


                </form>

            </div>
            <Link to='/'>아이디 또는 비밀번호를 잊어버렸습니까?</Link>
            <Link to='/'>계정이 없으신가요?</Link>
        </div>
    );
};

export default LoginPanel;