import './AppFooter.css'

const AppFooter = () => {
    return (
        <div className = 'footer-wrapper'>
            <footer className = 'footer-container'>
                <p>Copyrights(c) 2023 All rights reserved by cupokki.</p>
                <p>contact | nd1346@gmail.com</p>
            </footer>
        </div> 
     );
}

export default AppFooter;