const NotFoundPage = () => {
 
    return (
        <section className=''>
            404 Not Found
            
        </section>
    );
}

export default NotFoundPage;