import { useEffect } from "react";

const QuizPage = () => {
    useEffect(()=>{
        



    //eslint-disable-next-line react-hooks/exhaustive-deps  
    },[])
    return (
        <section className=''>
            <h3>📝QUIZ</h3>
            <article>

            </article>
        </section>
    );
}

export default QuizPage;