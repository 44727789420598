import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import AppMain from './components/AppMain'
import ListPage from './pages/ListPage';
import QuizPage from './pages/QuizPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <div className="App">
      <Router>
        <AppHeader />
        <AppMain>
          <Routes>
            <Route path="/" element={<ListPage />} />
            <Route path="/quiz" element={<QuizPage />} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/*" element={<NotFoundPage/>} />
          </Routes>
        </AppMain>
        <AppFooter />
      </Router>
    </div>
  );
}

export default App;
