import { useEffect, useState } from 'react'
import VocaCard from '../components/VocaCard';
import Advertisement from '../components/Advertisement';


// fetch()
const ListPage = () => {
    const [vocaList, setVocaList] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://port-0-myvocaserver-dr3h12alpy7q141.sel4.cloudtype.app/');
                // const response = await fetch('http://localhost:8000');
                const result = await response.json();
                setVocaList(result);

            } catch (e) {
                console.error('데이터 로드실패', e);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <section className =''>
            
            <h4>📃 단어 목록</h4>
            <article className='section-card-list'>
                <Advertisement/>
                {
                    vocaList ?
                        vocaList.map((item, idx) => { 
                            return (
                                <>
                                {/* {idx %10 ?<></>:<Advertisement key={idx} data={idx}/>} */}
                                <VocaCard key={idx} data={item}/>
                                </>
                            )
                        }) :
                        <span>Loading...</span>
                }
                {/* {vocaList.map((item, idx) => (
                <span key={idx}>{item}</span>
            ))} */}

            </article>
        </section>
    )
}

export default ListPage;