import { useEffect } from "react";

const Advertisement = ({data}) =>{
    // const id ='adfit_'+ data
    useEffect(()=>{
        let ins = document.createElement('ins');
        let scr = document.createElement('script');

        ins.className = 'kakao_ad_area';
        // ins.style = "display:none;";// width:100%;
        ins.style = "display:none; width:100%;";
        scr.async = 'true';
        scr.type = "text/javascript";
        scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
        ins.setAttribute('data-ad-width', '320');
        ins.setAttribute('data-ad-height', '100');
        ins.setAttribute('data-ad-unit', 'DAN-GtYwdLvXttlo5pBG');

        // document.querySelector('.adfit').appendChild(ins);
        // document.querySelector('.adfit').appendChild(scr);
        document.querySelector('.adfit_'+data).appendChild(ins);
        document.querySelector('.adfit_'+data).appendChild(scr);
    //     <ins class="kakao_ad_area" style="display:none;" data-ad-unit="DAN-GtYwdLvXttlo5pBG" data-ad-width="320"
    //     data-ad-height="100"></ins>
    //   <script type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></script>
    // eslint-disable-next-line
    },[])
    return(
        <div className={"adfit_"+data}>
            
        </div>
    )
}
export default Advertisement;