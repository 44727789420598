import './AppMain.css'

const AppMain = (props) => {
   return (
      <div className="main-wrapper">
         <div className="main-container">
            <main className="main-main">
               {props.children}
            </main>
         </div>
      </div>
   );
}

export default AppMain;

